import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageGrid = makeShortcode("ImageGrid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "assertive-communication"
    }}>{`Assertive communication`}</h1>
    <h3 {...{
      "id": "the-art-of-managing-conflict-respectfully"
    }}>{`The art of managing conflict respectfully`}</h3>
    <p>{`Part of helping your teenager manage conflict is helping them learn assertive communication. You can do so by teaching them the difference between being assertive and being aggressive. Showing them through your own behaviour how to be assertive in everyday situations is also very helpful.`}</p>
    <h3 {...{
      "id": "what-is-assertive-communication"
    }}>{`What is assertive communication?`}</h3>
    <ImageGrid list={[{
      src: '/images/m5/Passive_Transparant.gif',
      text: <p>
          <b>Passive</b>
        </p>
    }, {
      src: '/images/m5/Assertive_Transparant.gif',
      text: <p>
          <b>Assertive</b>
        </p>
    }, {
      src: '/images/m5/Aggressive_Transparant.gif',
      text: <p>
          <b>Aggressive</b>
        </p>
    }]} mdxType="ImageGrid" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      